import React, { useEffect } from "react";
import HeroSectionContentRight from "components/HeroSectionContentRight";
import FeaturesSection from "components/FeaturesSection";
import LandingCombinedSection from "components/LandingCombinedSection";
import AnchorBeforeSection from "components/AnchorBeforeSection";
import { useRouter } from "next/router";
import TeamBiosSection2 from "components/TeamBiosSection2";
import Appstore from "components/Appstore";
import EarlySignupForm from "components/forms/EarlySignupForm";
import OwnerLandingPageCTA from "components/landing/OwnerLandingPageCTA";
import SectionHeader from "components/SectionHeader";
import ClientsSection from "components/ClientsSection";
import Head from "next/head";
import FaqSection from "components/FaqSection";
import { ANCHORS } from "util/Config";

function IndexPage(props) {
  const router = useRouter();

  useEffect(() => {
    router.push('/auth/signin?next=/dashboard');
  });

  return <></>;

  // // navigate to specific parts of the page marked by anchors
  // useEffect(() => {
  //   let id = router.asPath.match(/#([a-z0-9]+)/gi);
  //   if (id) {
  //     console.log("id: ");
  //     console.log(id[0]);
  //     location.href = id[0];
  //   } else {
  //     // something else
  //   }
  // }, [props.showLastUsed]);

  // return (
  //   <>
  //     <Head>
  //       <title>
  //         Automobilių Nuoma | Automobilių dalinimosi platforma | TryMyCar
  //       </title>
  //     </Head>
  //     <LandingCombinedSection
  //       mainImage="https://d2c95mwu0p1ney.cloudfront.net/trymycar_web_mobile_screenshot_optimized.png"
  //       content={
  //         <>
  //           <SectionHeader
  //             title="Pirmoji automobilių dalinimosi platforma Lietuvoje, kur galima ne tik išsinuomoti, bet ir išnuomoti savo automobilį."
  //             size={2}
  //             spaced={false}
  //           />
  //           <Appstore
  //             title="Ieškai automobilio nuomai?"
  //             title2="Parsisiųsk programėlę!"
  //           />
  //           <OwnerLandingPageCTA title="Nori dalintis savo automobiliu ir užsidirbti?" />
  //         </>
  //       }
  //     />
  //     <ClientsSection
  //       title=""
  //       subtitle="Mūsų sprendimo partneriai:"
  //       className="text-left mb-0"
  //     />

  //     <AnchorBeforeSection anchorText="about" />
  //     <HeroSectionContentRight
  //       anchortext="about"
  //       bg="white"
  //       textColor="dark"
  //       size="md"
  //       bgImage=""
  //       bgImageOpacity={1}
  //       title="Apie mus"
  //       subtitle="„TryMyCar“ yra tarpusavio automobilių dalijimosi platforma, demokratizuojanti automobilių nuomos rinką Lietuvoje ir leidžianti tau įdarbinti automobilį tada, kai jis stovi nenaudojamas."
  //       buttonText="Get Started"
  //       buttonColor="primary"
  //       video={
  //         <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
  //           <iframe
  //             src="https://player.vimeo.com/video/526872701?color=ffffff&title=0&byline=0&portrait=0"
  //             style={{
  //               position: "absolute",
  //               top: 0,
  //               left: 0,
  //               width: "100%",
  //               height: "100%",
  //             }}
  //             frameBorder="0"
  //             allow="autoplay; fullscreen; picture-in-picture"
  //             allowFullScreen
  //           ></iframe>
  //         </div>
  //       }
  //       buttonOnClick={() => {
  //         // Navigate to pricing page
  //         router.push("/pricing");
  //       }}
  //     />

  //     <AnchorBeforeSection anchorText="features" />
  //     <FeaturesSection
  //       bg="white"
  //       textColor="dark"
  //       size="md"
  //       bgImage=""
  //       bgImageOpacity={1}
  //       title="Savybės"
  //       subtitle=""
  //     />

  //     <AnchorBeforeSection anchorText="team" />
  //     <TeamBiosSection2
  //       bg="white"
  //       textColor="dark"
  //       size="md"
  //       bgImage=""
  //       bgImageOpacity={1}
  //       title="Mūsų komanda"
  //       subtitle=""
  //     />
  //     <AnchorBeforeSection anchorText={`${ANCHORS.CONTACTS_ANCHOR_LINK}`} />
  //   </>
  // );
}

export default IndexPage;
