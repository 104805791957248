import React, { useState } from "react";
import Section from "components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "components/SectionHeader";
import Appstore from "components/Appstore";
import styles from "./FaqSection.module.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "Kaip įdarbinti savo automobilį?",
      answer:
      <div>
        <p> Jeigu Jūsų automobilis tenkina <a href="/eligibility">tinkamumo reikalavimus</a> ir yra mažai naudojamas, jį įdarbinti užtruks kelias minutes:</p>
        <ol>
        <li><a href="/auth/signin?next=/dashboard">Užsiregistruokite</a> platformoje, įvesdami savo telefono numerį, vardą bei elektroninį paštą. Elektroninis paštas bus naudojamas informuoti apie nuomą, tad paprašysime jį patvirtinti atskiru elektroniniu paštu.</li>
        <li><a href="dashboard/add-car">Įkelkite</a> savo automobilį. Jeigu iš kart neturite automobilio nuotraukos, ją galėsite pridėti vėliau. Atumobililai su tinkamomomis nuotraukomis susilaukia didesnio vartotojų susidomėjimo.</li>
        <li> Pasirinkite tinkamą kainodarą, kuri atitinka automobilio amžių, ridą bei kitas jo savybes. Automobilių rinka yra sezoniška, tad sekite kainas ir jas koreguokite. Greitu metu galėsime pasiūlyti rekomenduojamą kainą būtent Jūsų automobiliui. Nors savininkui nebūtina parsisųsti TryMyCar programėlės, tai puikus būdas stebėti rinką.</li>
        <li> Nustatykite, kada automobilis yra naudojomas kitoms reikmėms ir neturėtų būti prieinamas nuomai per TryMyCar platformą.</li>
        <li> Gavę užklausą iš potencialaus nuomininko galėsite ją patvirtinti, arba atšaukti, jeigu pasikeitė aplinkybės ir automobilis tuo metu bus užimtas.</li>
        </ol>
      </div>,
    },
    {
      question: "Kaip išsinuomoti automobilį?",
      answer:
      <div>
      <p> Jeigu Jūs tenkinate <a href="/eligibility">būtinasias sąlygas</a> išsinuomoti automobilį per TryMyCar bus daugiau negu paprasta:</p>
      <ol>
      <li>Parsisiųsk programėlę:</li>
      <Appstore
              title=""
              title2=""
            />
      <li> Nurodykite savo telefono numerį, vardą bei elektroninį paštą. Elektroninis paštas bus naudojamas informuoti apie nuomą, tad paprašysime jį patvirtinti atskiru elektroniniu paštu.</li>
      <li> Naudodami programėlę nufotografuokite ir pateikite vairuotojo teisių ir savo nuotrauką. Dienos bėgyje patvirtinsime Jūsų tapatybę, o kol tikriname tapatybę apsižvalgykite, kokie automobiliai nuomojami aplink.</li>
      <li> Pasirinkite laikotarpį kuriam ieškote automobilio ir programėlėje iš karto matysite tik tuos automobilius, kurie pažymėti kaip neužimti.</li>
      <li> Įsitikinkite, kad nuomos sąlygos (savininko taikomas depozitas ir kaina) Jums yra tinkami. Nurodoma nuomos kaina yra už visą pasirinktą nuomos laikotarpį. Atkreipkite dėmesį, kad automobilį nuomojant ilgesniam laikotarpiui dažnai paros kaina yra mažesnė nei nuomojant trumpam.</li>
      <li> Jeigu Jūsų tapatybė patvirtinta (apie tai pranešime atskiru elektroninu laišku) ir radote Jums tinkantį automobilį, rezervuokite jį programėlėje ir laukite savininko patvirtinimo.</li>
      <li> Savininkui patvirtinus rezervaciją gausite pranešimą kaip susisiekti su savininku dėl automobilio perdavimo, o jeigu automobilis turi nuotolinio atrakinimo funkcionalumą, jį galėsite atrakinti ir užrakinti tiesiog programėlės pagalba.</li>
      <li> Jeigu savininkas rezervacijos nepatvirtino arba atmetė, pasirinkite kitą automobilį. Greitu metu sudarysime galimybę vienu metu pateikti užklausą keliems savininkams.</li>
      <li> Jeigu pasikeitė Jūsų planai, rezervaciją galite atšaukti pagal nurodytas<a href="/tc"> sąlygas</a>.</li>
      
      </ol>
    </div>,
    },
    {
      question: "Kaip perduoti ir grąžinti automobilį?",
      answer:
      <div>
      <p> Automobilį nuomoninkui perduoda savininkas, nebent automobilyje sumontuota <a href="/iot">TryMyCar Saugiai</a> įranga leidžianti atrakinti ir užrakinti automobilį programėle be poreikio perduoti ir atsiimti automobilio raktus. Jeigu sudomino <a href="/iot">TryMyCar Saugiai</a> susisiekite su mumis.</p>
      <ol>
      <li> Nuomos patvirtinimo pranešime rasite savininko kontaktus susiderinti automobilio perdavimo ir grąžinimo aplinkybes.</li>
      <li> Atvykus atsiimti automobilį pradėkite nuomą apie tai patvirtindami programėlėje (nepriklausomai nuo to ar automobilis turi <a href="/iot">TryMyCar Saugiai</a> įrangą).</li>
      <li> Naudodamiesi programėle sekite nurodymus ir nufotografuokite automobilio išorę ir vidų.</li>
      <li> Mums savarbus Jūsų saugumas. Jeigu kilo įtarimų dėl automobilio techninės būklės ar susidūrėte su susunkumais, kreipkitės į savininką. Neradus sprendimo, sprendimo visadą rasime mes.</li>
      <li> Grąžindami automobilį nepamirškite papildyti degalais ar pakrauti bateriją. Jeigu reikia, nuplaukite.</li>
      <li> Jeigu nuomos metu patekote į autoįvykį ar kitaip apgadinote automobilį, visada praneškite savininkui ir mums. Mes esame tam, kad netikėti nemalonumai neusgadintų nuotaikos ir būtų išspręsti tinkamai.</li>
      </ol>
    </div>,
    },
    {
      question: "Mokėjimai",
      answer:
      <div>
      <p> Jūsų patogumui TryMyCar programėlė turi integruotą mokėjimų paslaugą ir leidžia už paslaugas apmokėti tiek naudojantis Apple Pay / Google Pay tiek mokėjimo kortele.</p>
      <ol>
      <li> Automobilio rezervacijos metu rezervuojamos lėšos už automobilio nuomą ir (savininko pasirinkimu) rezervacijos depozitą.</li>
      <li> Atšaukus rezervaciją lėšų rezervacija atšaukiama pagal nurodytas<a href="/tc"> sąlygas</a>.</li>
      <li> Savininkams apmokėjimą už nuomą atskaičius platformos naudojimo mokestį periodiškai pervesime į nurodytą sąskaitą. Jūsų patogumui naudojame mūsų partnerio Stripe paslaugą Stripe Connect, kuri leidžia administruoti surinktas lėšas bei kitą su nuomą susijusią informaciją.</li>
      </ol>
      </div>,
    },
    {
      question: "Draudimas",
      answer:
      <div>
      <ol>
      <li> Automobilio savininkas turėtų įsitikinti, kad automobilio civilinės atsakomybės draudimas apima automobilio nuomą. </li>
      <li> Jeigu nesate tikri, pasitikrinkite su savo draudimo kompaniją. Jeigu pageidaujate, automobilio nuomos metu galime pasiūlyti mūsų partnerio IF automobilio kasko draudimą.</li>
      </ol>
      </div>,        
    },
    {
      question: "Pagalba kelyje",
      answer:
        "Skambinkite +37052555784, patiekite prašomą informaciją ir Jums bus suteikta pagalba kelyje.",
    },    
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
