import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import newsletter from "util/newsletter.js";
import FormFieldFeedbackTop from "components/forms/FormFieldFeedbackTop";
import SectionHeader from "components/SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


// NOTE: this file was auto-converted from plain HTML to JSX, so might be not very well structured.
function LandingCombinedSection(props) {
  return (
    <div style={{ margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
      <div className="container">
        <Row 
          className="align-items-center" 
          style={ 
            (props.reverse && {flexDirection: "row-reverse"})
          }
        >
          <div className="col-md-7">
            {props.content}
          </div>
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={props.mainImage}
              style={{ maxHeight: "503px", margin: "20px 0px 0px 0px" }}
            />
          </div>
        </Row>
      </div>
    </div>
  );
}

export default LandingCombinedSection;
